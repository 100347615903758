/** @format */

import { MdKeyboardArrowRight } from "react-icons/md";
import mob from "assets/Home page/Mobile mockup.png";
import Volunteer from "components/displayData/Volunteer";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

const WhatWeDo = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const fade = useAnimation();
  useEffect(() => {
    if (inView) {
      fade.start({
        opacity: 1,
        transition: { duration: 0.5 },
      });
    }
    if (!inView) {
      fade.start({
        opacity: 0,
      });
    }
  }, [inView]);

  const [monitored, setMonitored] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setMonitored(data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div
      ref={ref}
      className="w-full h-full font-custom2 bg-white  dark:!bg-darkMain max-w-screen-xl px-8 mx-auto"
    >
      {/* header */}
      <div className="flex pt-[40px] pb-[20px] xl:pb-0 xl:pt-[90px] justify-center items-center gap-3">
         {/*<motion.div
          animate={fade}
          className=" w-[50px] md:w-[80px] h-[4px] dark:bg-white rounded-3xl bg-[#CED8E9] "
        ></motion.div>*/}
        <motion.h1
          // initial={{ opacity: 0, scale: 0.5 }}
          // animate={{ opacity: 1, scale: 1 }}
          // transition={{ duration: 0.5, delay: 1 }}
          animate={fade}
          className=" text-[#CED8E9] dark:text-white text-[15px] md:text-[40px] "
        >
        {/*  What We Do */}
        </motion.h1>
      {/*  <motion.div
          animate={fade}
          className=" w-[50px] md:w-[80px] h-[4px] dark:bg-white rounded-3xl bg-[#CED8E9] "
        ></motion.div>*/}
      </div>
      {/* monitoring */}
      <div className="container mx-auto  grid grid-cols-1 md:grid-cols-2  pb-[50px] justify-between items-center bg-white dark:bg-darkMain">
        <div className="col-span-1 bg-white dark:bg-darkMain w-fit">
          <motion.div animate={fade} className="w-full h-full">
            <img src={mob} alt="" />
          </motion.div>
        </div>

        <motion.div
          animate={fade}
          className="flex flex-col items-start col-span-1 "
        >
          <h1 className=" text-[33px] dark:text-white leading-10 xl:text-[40px] font-banscrift font-semibold text-[#1AA9B8] ">
            Community-Based Monitoring{" "}
          </h1>
          <div>
            <p className=" text-base font-calibrilight xl:text-[22px] mt-[30px] dark:text-white text-[#575757]  ">
              Community-Based Monitoring (CBM) is the flagship program of Integrity Watch, widely used to engage communities in promoting transparency, accountability, and efficiency in the delivery of public services and projects.
            </p>
            <p className="text-base xl:text-[22px] mt-4 text-[#575757] dark:text-white font-calibrilight  ">
              Integrity Watch first used CBM in 2007, mobilizing communities in
              Parwan Province, Afghanistan, to monitor a small number of
              infrastructure projects. The success of the program soon led to
              the expansion of CBM to other provinces, and then to other
              sectors: trial courts, education, extractives, and healthcare. Our
              particular model of CBM, which involves the training and ongoing
              support of community-elected volunteers, has proved highly
              sustainable and successful in tackling corruption and promoting
              integrity. It has raised public awareness about the nature and
              effects of corruption, empowered citizens to advocate,
              problem-solve and effect change, and encouraged decision-makers to
              adopt greater transparency in policies and legislation.
            </p>
          </div>
          {/* button */}
          <motion.button>
            <Link
              to={`/whatwedo`}
              className="flex items-center pl-3 pr-1 py-1 justify-center mt-[16px] transition-colors ease-in-out duration-700 hover:bg-[#005362] dark:border-white dark:text-white dark:hover:bg-darkMain hover:text-white text-[15px] rounded-2xl text-[#636369] border border-[#636369]  "
            >
              Read more
              <p className="text-xl  mt-[2px] ">
                <MdKeyboardArrowRight />
              </p>
            </Link>
          </motion.button>
        </motion.div>
      </div>
      {/* data */}

      <Volunteer
        volunteers={monitored["total-volunteers"]}
        problemsIdentified={monitored["total-problems"]}
        problemsSolved={monitored["total-problems-solved"]}
        sitesMonitored={monitored["total-sites-monitored"]}
      />
    </div>
  );
};

export default WhatWeDo;
