/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import { useState } from "react";
import Header from "./components/Header";
import InnerAbout from "./components/InnerAbout";
import OurTeam from "./components/OurTeam";
import Policies from "./components/Policies";

const About = () => {
  const [active, setActive] = useState("aboutus");
  return (
    <div className="w-full h-full bg-white dark:bg-darkMain">
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg="bg-[#FAFAFA] dark:bg-darkHeader"
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        <Header />
      </div>
      {/* main */}
      <div className="h-full max-w-screen-xl mx-auto mt-16 pb-36 rounded-2xl">
        {/* header */}
        <div className="flex w-full mb-12 justify-center gap-7 mt-[28px] items-center">
          <h1
            onClick={() => setActive("aboutus")}
            className={`text-sm md:text-[24px]  text-textSecondary  transition-all duration-300 hover:cursor-pointer uppercase ${
              active === "aboutus"
                ? "border-b-[3px] dark:text-white dark:border-white font-bold border-textSecondary "
                : "border-b-[3px] dark:text-grayPrimary dark:border-darkMain border-white"
            } `}
          >
            {/*About Us*/}
          </h1>
          {/* <h1
            onClick={() => setActive("ourpolicies")}
            className={`text-sm md:text-[24px]  text-textSecondary  transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "ourpolicies"
                ? "border-b-[3px] font-bold dark:text-white dark:border-white border-textSecondary "
                : "border-b-[3px] dark:text-grayPrimary dark:border-darkMain border-white"
            } `}
          >
            Our Policies
          </h1> */}
          {/* <h1
            onClick={() => setActive("ourteam")}
            className={` text-sm md:text-[24px]  text-textSecondary  transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "ourteam"
                ? "border-b-[3px] dark:text-white dark:border-white font-bold border-textSecondary "
                : "border-b-[3px] dark:border-darkMain dark:text-grayPrimary border-white"
            } `}
          >
            Our Team
          </h1> */}
        </div>
        <div>{active === "aboutus" ? <InnerAbout /> : null}</div>
        {/* <div>{active === "ourpolicies" ? <Policies /> : null} </div> */}
        {/* <div>{active === "ourteam" ? <OurTeam /> : null} </div> */}
      </div>
    </div>
  );
};

export default About;
