/** @format */

import { TiSocialFacebook } from "react-icons/ti";
import { FaTwitter } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { BsTwitter } from "react-icons/bs";
import FooterValidation from "./FooterValidate";

const Footer = () => {
  // file: Yup.mixed().required('File is required'),
  const ValidateSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is Invalid")
      .required("Email is Required!"),
    message: Yup.string().required("Required!"),
  });

  // const load = () => {
  //   window.location.reload();
  // };
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // var formData = new FormData();
    // formData.append("email", email);
    // formData.append("message", message);
    const { result, error } = await axios.post(
      `${process.env.REACT_APP_Backend_API}/api/contact/`,
      {
        email,
        message,
      },
      {
        "Content-Type": "multipart/form-data",
      }
    );

    if (error) {
      return console.log(error);
    }
    setEmail(null);
    setMessage(null);
    setIsFormSubmitted(true);
    setSubmitting(false);

    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 4000);

    // try {
    //   await axios.post(
    //     `${process.env.REACT_APP_Backend_API}/api/contact/`,
    //     {
    //       email,
    //       message,
    //     },
    //     {
    //       "Content-Type": "multipart/form-data",
    //     }
    //   );
    //   // window.location.reload();
    //   // navigate("/");
    //   // e.target.reset();

    // } catch (error) {
    //   console.log(error);
    //   setMsg({ type: "error", error });
    // } finally {
    //   setSubmitting(false);
    // }
  };

  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();
  const animation1 = useAnimation();
  const fade = useAnimation();
  useEffect(() => {
    if (inView) {
      fade.start({
        opacity: 1,
        transition: { duration: 0.5, delay: 0.2 },
      });
    }
    if (!inView) {
      fade.start({
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <footer ref={ref} className="bg-[#1AA9B8] dark:bg-dark w-full pb-4 ">
      <div className="w-full md:max-w-screen-xl md:mx-auto px-10 lg:px-0  font-custom2  grid grid-cols-11   pt-[50px] h-fit xl:h-[511px]">
        <motion.div
          animate={fade}
          className="items-start w-full col-span-11 lg:col-span-4"
        >
          {/* header */}
          <h1 className="text-[24px] lg:pt-6 uppercase text-white font-bold ">
            about us
          </h1>
          <p className="text-white w-full font-calibriregular text-justify text-base lg:text-sm xl:text-base mt-[18px] ">
            Integrity Watch is a not-for-profit organization seeking to promote
            greater integrity and accountability among power holders, increased
            citizen participation in decision-making on services impacting their
            lives, and an end to corruption and abuse of power. We specialize in
            grassroots-led collective action, working with local communities,
            civil society, media actors, academia, government officials,
            judicial actors, public representatives, development partners, and
            private sector actors.<br/>
			
          </p>
        </motion.div>

        {/* middle  */}
        <motion.div
          animate={fade}
          className="flex flex-col justify-center w-full col-span-11 mt-4 lg:pb-24 lg:mt-0 xl:px-14 lg:col-span-3 "
        >
          <div className="col-span-1">
            <h1 className=" text-[22px] leading-7 font-bold text-white uppercase ">
              Our Programs
            </h1>
            <Link
              to={"/whatwedo"}
              className=" mt-[18px] text-[16px] scale-95 hover:scale-100 duration-200 font-calibriregular text-white leading-6 "
            >
              What We Do
            </Link>
          </div>

          <div className="mt-[20px] col-span-1 ">
            <h1 className=" text-[22px] leading-7 font-bold text-white uppercase ">
              announcements
            </h1>
            <Link
              to={"/announcement"}
              className=" mt-[15px] pb-1 block text-[16px] font-calibriregular text-white leading-6 "
            >
              Jobs & Bids
            </Link>
          </div>

          <div className="mt-[20px] col-span-1 ">
            <h1 className=" text-[22px] leading-7 font-bold text-white uppercase ">
              Links
            </h1>
            <p className=" mt-[14px] font-calibriregular text-[16px] text-white leading-6 ">
				<a href="https://communitymonitoring.org/"> Community monitoring </a>
            </p>
          </div>
          {/* links */}
          <div className=" mt-[24px] w-full text-white justify-start flex items-start gap-[20px] text-[18px] font-bold ">
            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125 ">
              <TiSocialFacebook />
            </div>
            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125 ">
              {" "}
              <svg
                className="!text-white"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  className="!text-white"
                  fill="#fff"
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                />
              </svg>
            </div>
            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125">
              {" "}
              <AiOutlineInstagram />
            </div>
            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125">
              <FaLinkedinIn />
            </div>
          </div>
        </motion.div>
        {/* right */}
        <div
          // animate={fade}
          className="flex flex-col items-start justify-center w-full col-span-11 mt-4 lg:mt-0 lg:px-10 lg:col-span-4 "
        >
          <h1 className=" text-[24px] mb-3 font-bold uppercase text-white leading-7 ">
            Contact us
          </h1>
          {/* <div className=" w-full mt-[20px]  text-white h-[50px] rounded-[10px] dark:!bg-dark bg-[#179DAB] ">
            <input
              placeholder="Enter your email"
              className=" outline-none dark:bg-darkMain placeholder:text-white border-none px-2 rounded-[10px] focus:border-none focus:outline-none text-white bg-[#179DAB] w-full h-full  "
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className=" w-full mt-[10px] border-none h-[240px] relative  rounded-[10px]  ">
            <textarea
              placeholder="Your message here"
              className="outline-none resize-none dark:bg-darkMain placeholder:text-white h-full rounded-[10px] px-2 text-white bg-[#179DAB] border-none focus:border-textPrimary focus:!outline-textPrimary w-full pt-1 bg[#179DAB] "
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          {isFormSubmitted ? (
            <div className="flex mt-1 rounded-lg shadow-lg">
              <div className="flex items-center px-6 py-2 bg-green-600 rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-white fill-current"
                  viewBox="0 0 16 16"
                  width="20"
                  height="20"
                >
                  <path
                    fillRule="evenodd"
                    d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
                  ></path>
                </svg>
              </div>
              <p className="flex items-center w-full px-4 py-6 text-sm bg-white border border-gray-200 rounded-r-lg md:text-base border-l-transparent dark:text-gray-600">
                Message sent! Thank you for reaching out. Well get back soon!
              </p>
            </div>
          ) : (
            <button
              disabled={submitting}
              type="submit"
              className=" w-[80px] pt-1 opacity-90 hover:opacity-100 transition-opacity mt-[20px] py-[4px] px-1 h-[32px] rounded-[8px] text-white bg-[#005462] "
            >
              {submitting && (
                <svg
                  className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  data-motion-id="svg 1"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              <span className="">{submitting ? "Sending" : "Send"}</span>
            </button>
          )} */}
          <FooterValidation />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
