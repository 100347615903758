/** @format */

import Earth from "components/icons/Earth";
import React from "react";

function CopyRightFooter() {
  return (
    <footer className='bg-[#005462] dark:!bg-[#15232F] px-8'>
      <div className='max-w-screen-xl mx-auto flex flex-col justify-center items-center   font-banscrift  w-full h-[110px] md:h-[90px]'>
        <div className='  text-center pb-1 '>
          <h1 className='text-white text-xs sm:text-base font-normal '>
            Terms & Privacy
          </h1>
        </div>
        <div className='  '>
          <h1 className=' border-t pt-2 text-center border-white text-[17px] flex items-end leading-6 ml-6 text-white  '>
        Integrity Watch © 2025 All Rights Reserved
          </h1>
        </div>
      </div>
    </footer>
  );
}

export default CopyRightFooter;
